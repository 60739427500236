import * as React from 'react';

const SvgIconPinMap = ({ type = 'default', ...props }) => {
  if (type === 'default') {
    return (
      <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5173 0.864216L10.3152 0.830528C9.4444 0.685397 8.5556 0.685397 7.68481 0.830528L7.48269 0.864216C3.74182 1.48769 1 4.7243 1 8.51677C1 11.1746 1.86204 13.7607 3.45674 15.887L9 23.278L14.5433 15.887C16.138 13.7607 17 11.1746 17 8.51677C17 4.7243 14.2582 1.48769 10.5173 0.864216ZM9 12.6113C11.2091 12.6113 13 10.8205 13 8.61133C13 6.40219 11.2091 4.61133 9 4.61133C6.79086 4.61133 5 6.40219 5 8.61133C5 10.8205 6.79086 12.6113 9 12.6113Z"
          fill="#EB0A1E"
        />
        <path
          d="M10.3152 0.830528L10.3974 0.337331L10.3152 0.830528ZM10.5173 0.864216L10.5995 0.371019L10.5173 0.864216ZM7.68481 0.830528L7.60261 0.337331V0.337331L7.68481 0.830528ZM7.48269 0.864216L7.56488 1.35741L7.48269 0.864216ZM3.45674 15.887L3.05674 16.187L3.45674 15.887ZM9 23.278L8.6 23.578L9 24.1113L9.4 23.578L9 23.278ZM14.5433 15.887L14.1433 15.587L14.5433 15.887ZM10.233 1.32373L10.4351 1.35741L10.5995 0.371019L10.3974 0.337331L10.233 1.32373ZM7.76701 1.32373C8.58337 1.18766 9.41663 1.18766 10.233 1.32373L10.3974 0.337331C9.47218 0.183129 8.52782 0.183129 7.60261 0.337331L7.76701 1.32373ZM7.56488 1.35741L7.76701 1.32373L7.60261 0.337331L7.40049 0.371019L7.56488 1.35741ZM1.5 8.51677C1.5 4.96872 4.06511 1.94071 7.56488 1.35741L7.40049 0.371019C3.41852 1.03468 0.5 4.47988 0.5 8.51677H1.5ZM3.85674 15.587C2.32695 13.5473 1.5 11.0664 1.5 8.51677H0.5C0.5 11.2828 1.39713 13.9742 3.05674 16.187L3.85674 15.587ZM9.4 22.978L3.85674 15.587L3.05674 16.187L8.6 23.578L9.4 22.978ZM14.1433 15.587L8.6 22.978L9.4 23.578L14.9433 16.187L14.1433 15.587ZM16.5 8.51677C16.5 11.0664 15.673 13.5473 14.1433 15.587L14.9433 16.187C16.6029 13.9742 17.5 11.2828 17.5 8.51677H16.5ZM10.4351 1.35741C13.9349 1.94071 16.5 4.96872 16.5 8.51677H17.5C17.5 4.47988 14.5815 1.03468 10.5995 0.371019L10.4351 1.35741ZM12.5 8.61133C12.5 10.5443 10.933 12.1113 9 12.1113V13.1113C11.4853 13.1113 13.5 11.0966 13.5 8.61133H12.5ZM9 5.11133C10.933 5.11133 12.5 6.67833 12.5 8.61133H13.5C13.5 6.12605 11.4853 4.11133 9 4.11133V5.11133ZM5.5 8.61133C5.5 6.67833 7.067 5.11133 9 5.11133V4.11133C6.51472 4.11133 4.5 6.12605 4.5 8.61133H5.5ZM9 12.1113C7.067 12.1113 5.5 10.5443 5.5 8.61133H4.5C4.5 11.0966 6.51472 13.1113 9 13.1113V12.1113Z"
          fill="#EB0A1E"
        />
      </svg>
    );
  }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75866 2.79295L8.6576 2.7761C8.2222 2.70354 7.7778 2.70354 7.3424 2.7761L7.24134 2.79295C5.37091 3.10469 4 4.72299 4 6.61922C4 7.94814 4.43102 9.2412 5.22837 10.3043L8 13.9998L10.7716 10.3043C11.569 9.2412 12 7.94814 12 6.61922C12 4.72299 10.6291 3.10469 8.75866 2.79295ZM8 8.6665C9.10457 8.6665 10 7.77107 10 6.6665C10 5.56193 9.10457 4.6665 8 4.6665C6.89543 4.6665 6 5.56193 6 6.6665C6 7.77107 6.89543 8.6665 8 8.6665Z"
        fill="black"
      />
      <path
        d="M8.6576 2.7761L8.5754 3.2693L8.5754 3.2693L8.6576 2.7761ZM8.75866 2.79295L8.84086 2.29975L8.84086 2.29975L8.75866 2.79295ZM7.3424 2.7761L7.4246 3.2693H7.4246L7.3424 2.7761ZM7.24134 2.79295L7.15914 2.29975L7.15914 2.29975L7.24134 2.79295ZM5.22837 10.3043L4.82837 10.6043L4.82837 10.6043L5.22837 10.3043ZM8 13.9998L7.6 14.2998L8 14.8332L8.4 14.2998L8 13.9998ZM10.7716 10.3043L10.3716 10.0043V10.0043L10.7716 10.3043ZM8.5754 3.2693L8.67646 3.28614L8.84086 2.29975L8.7398 2.28291L8.5754 3.2693ZM7.4246 3.2693C7.80557 3.20581 8.19443 3.20581 8.5754 3.2693L8.7398 2.28291C8.24998 2.20127 7.75002 2.20127 7.2602 2.28291L7.4246 3.2693ZM7.32354 3.28614L7.4246 3.2693L7.2602 2.28291L7.15914 2.29975L7.32354 3.28614ZM4.5 6.61922C4.5 4.96741 5.6942 3.5577 7.32354 3.28614L7.15914 2.29975C5.04762 2.65167 3.5 4.47857 3.5 6.61922H4.5ZM5.62837 10.0043C4.89593 9.02775 4.5 7.83995 4.5 6.61922H3.5C3.5 8.05632 3.96611 9.45465 4.82837 10.6043L5.62837 10.0043ZM8.4 13.6998L5.62837 10.0043L4.82837 10.6043L7.6 14.2998L8.4 13.6998ZM10.3716 10.0043L7.6 13.6998L8.4 14.2998L11.1716 10.6043L10.3716 10.0043ZM11.5 6.61922C11.5 7.83995 11.1041 9.02775 10.3716 10.0043L11.1716 10.6043C12.0339 9.45465 12.5 8.05632 12.5 6.61922H11.5ZM8.67646 3.28614C10.3058 3.5577 11.5 4.96741 11.5 6.61922H12.5C12.5 4.47857 10.9524 2.65167 8.84086 2.29975L8.67646 3.28614ZM9.5 6.6665C9.5 7.49493 8.82843 8.1665 8 8.1665V9.1665C9.38071 9.1665 10.5 8.04722 10.5 6.6665H9.5ZM8 5.1665C8.82843 5.1665 9.5 5.83808 9.5 6.6665H10.5C10.5 5.28579 9.38071 4.1665 8 4.1665V5.1665ZM6.5 6.6665C6.5 5.83808 7.17157 5.1665 8 5.1665V4.1665C6.61929 4.1665 5.5 5.28579 5.5 6.6665H6.5ZM8 8.1665C7.17157 8.1665 6.5 7.49493 6.5 6.6665H5.5C5.5 8.04722 6.61929 9.1665 8 9.1665V8.1665Z"
        fill="black"
      />
    </svg>
  );
};

export default SvgIconPinMap;
