import * as React from 'react';

const SvgIconError = (props) => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.67359 10.5C8.8412 10.5 8.98179 10.4447 9.09538 10.3341C9.20897 10.2234 9.26576 10.0863 9.26576 9.92282C9.26576 9.75927 9.20907 9.62208 9.0957 9.51125C8.98232 9.40042 8.84183 9.345 8.67424 9.345C8.50663 9.345 8.36603 9.40031 8.25245 9.51093C8.13886 9.62157 8.08207 9.75865 8.08207 9.92218C8.08207 10.0857 8.13875 10.2229 8.25212 10.3337C8.36551 10.4446 8.506 10.5 8.67359 10.5ZM8.18967 7.8225H9.26576V3.395H8.18967V7.8225ZM8.67868 14C7.68947 14 6.75985 13.8162 5.88982 13.4488C5.0198 13.0813 4.25897 12.5796 3.60734 11.9438C2.95571 11.3079 2.44158 10.5651 2.06495 9.71528C1.68832 8.86548 1.5 7.95747 1.5 6.99125C1.5 6.02503 1.68832 5.11702 2.06495 4.26722C2.44158 3.41741 2.95571 2.6775 3.60734 2.0475C4.25897 1.4175 5.02025 0.91875 5.89117 0.55125C6.76208 0.18375 7.69265 0 8.68288 0C9.67311 0 10.6037 0.18375 11.4746 0.55125C12.3455 0.91875 13.1038 1.4175 13.7495 2.0475C14.3951 2.6775 14.9062 3.41833 15.2829 4.27C15.6595 5.12167 15.8478 6.03012 15.8478 6.99535C15.8478 7.96058 15.6595 8.86766 15.2829 9.7166C14.9062 10.5655 14.3951 11.3068 13.7495 11.9405C13.1038 12.5742 12.3446 13.0759 11.4717 13.4455C10.5989 13.8152 9.66789 14 8.67868 14ZM8.68288 12.95C10.3747 12.95 11.8125 12.3696 12.9962 11.2087C14.1799 10.0479 14.7717 8.64208 14.7717 6.99125C14.7717 5.34042 14.181 3.9375 12.9996 2.7825C11.8181 1.6275 10.3762 1.05 8.67391 1.05C6.98804 1.05 5.55027 1.6264 4.3606 2.77921C3.17092 3.93203 2.57609 5.33896 2.57609 7C2.57609 8.645 3.17092 10.0479 4.3606 11.2087C5.55027 12.3696 6.99103 12.95 8.68288 12.95Z"
      fill="white"
    />
  </svg>
);

export default SvgIconError;
