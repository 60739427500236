/* eslint-disable max-len */
import MainNavigation from 'components/molecules/main-navigation';
import React from 'react';
import Section from 'components/utils/section';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import { SearchIcon } from 'components/icons';
import HeaderLoginNavigation from '../header-login-navigation';
import getConfig from 'next/config';

/**
 * Organism Header
 *
 * The portal header component
 */
function Header(props) {
  const { className = '', children, menuData = null, isRendering, fullpath, ...other } = props;

  const gt = i18n.useTranslations('global');
  const { publicRuntimeConfig } = getConfig();
  const isFeatureOnelogin = publicRuntimeConfig?.FEATURE_ONELOGIN;

  return (
    <>
      <header className={`${css['header-wrapper']} ${className}`} data-is-rendering={isRendering ? 1 : null} {...other}>
        <Section tag="div" className={css['header-container']}>
          {fullpath === '/' ? (
            <h1 className={css['header-logo']}>
              <img className={css['header-logo__image']} src="/images/logos/logo-toyota.svg" alt="Toyota" />
            </h1>
          ) : (
            <a href="/" title={gt('back-to-home')} className={css['header-logo__anchor']}>
              <img className={css['header-logo__image']} src="/images/logos/logo-toyota.svg" alt="" />
              <span data-visuallyhidden>{`${gt('back-to-home')} Toyota`}</span>
            </a>
          )}
          <div className={css['header-navigation']}>
            {menuData && <MainNavigation menuData={menuData} />}
            <a href={gt('page-search-url')} title={gt('navigate-search')} className={css['search-button']}>
              <SearchIcon className={css['icon-search']} />
            </a>
            {isFeatureOnelogin && <HeaderLoginNavigation />}
          </div>
        </Section>
      </header>
    </>
  );
}

export default Header;
