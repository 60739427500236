import * as React from 'react';

const SvgIconNewSchedule = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon="1"
    {...props}
  >
    <path
      d="M18.1562 22V19H15.1562V17.5H18.1562V14.5H19.6562V17.5H22.6562V19H19.6562V22H18.1562ZM5.15625 20C4.75625 20 4.40625 19.85 4.10625 19.55C3.80625 19.25 3.65625 18.9 3.65625 18.5V5C3.65625 4.6 3.80625 4.25 4.10625 3.95C4.40625 3.65 4.75625 3.5 5.15625 3.5H6.78125V2H8.40625V3.5H14.9062V2H16.5312V3.5H18.1562C18.5563 3.5 18.9062 3.65 19.2063 3.95C19.5063 4.25 19.6562 4.6 19.6562 5V12.55C19.4062 12.5167 19.1562 12.5 18.9062 12.5C18.6562 12.5 18.4062 12.5167 18.1562 12.55V9.75H5.15625V18.5H13.1562C13.1562 18.75 13.1813 19 13.2312 19.25C13.2812 19.5 13.3479 19.75 13.4313 20H5.15625ZM5.15625 8.25H18.1562V5H5.15625V8.25Z"
      fill="black"
    />
  </svg>
);

export default SvgIconNewSchedule;
