import * as React from 'react';

const SvgIconDelete = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.4 21C7 21 6.65 20.85 6.35 20.55C6.05 20.25 5.9 19.9 5.9 19.5V5.25H4.875V3.75H9.575V3H16.175V3.75H20.875V5.25H19.85V19.5C19.85 19.9 19.7 20.25 19.4 20.55C19.1 20.85 18.75 21 18.35 21H7.4ZM10.05 17.35H11.55V7.375H10.05V17.35ZM14.2 17.35H15.7V7.375H14.2V17.35Z"
      fill="black"
    />
  </svg>
);

export default SvgIconDelete;
