import * as React from 'react';

const SvgIconPause = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.6665 13V3" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.3335 13V3" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default SvgIconPause;
