import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';
import { PlayIcon as Icon } from '../../icons';

/**
 * Atom PlayIcon
 * 
 * a youtube play icon
 */
function PlayIcon (props) {

  const {
    className = "",
    children,
    size = 3,
    ...others
  } = props;

  return <Icon className={`${css["icon-play"]} ${className}`} data-size={size} {...others}/>;
}

export default PlayIcon;
