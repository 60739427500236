import * as React from 'react';

const SvgIconServiceHistory = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path
      d="M18.2816 20.8L11.8316 14.3C11.4483 14.4333 11.065 14.5417 10.6816 14.625C10.2983 14.7083 9.90664 14.75 9.50664 14.75C7.88859 14.75 6.51324 14.1861 5.38059 13.0583C4.24796 11.9306 3.68164 10.5611 3.68164 8.95C3.68164 8.43333 3.74962 7.9301 3.88559 7.4403C4.02156 6.95048 4.21191 6.48705 4.45664 6.05L8.08164 9.675L10.3816 7.525L6.65664 3.8C7.08856 3.54737 7.54653 3.35088 8.03057 3.21053C8.51462 3.07018 9.00664 3 9.50664 3C11.1594 3 12.5643 3.57847 13.7212 4.73542C14.8782 5.89236 15.4566 7.29722 15.4566 8.95C15.4566 9.35 15.415 9.74167 15.3316 10.125C15.2483 10.5083 15.14 10.8917 15.0066 11.275L21.4816 17.725C21.665 17.9076 21.7566 18.1283 21.7566 18.387C21.7566 18.6457 21.665 18.8667 21.4816 19.05L19.5816 20.8C19.4034 20.9833 19.188 21.075 18.9354 21.075C18.6829 21.075 18.465 20.9833 18.2816 20.8ZM18.9816 19.375L19.9816 18.375L13.1566 11.55C13.4233 11.2 13.6233 10.7875 13.7566 10.3125C13.89 9.8375 13.9566 9.38333 13.9566 8.95C13.9566 7.7 13.4941 6.64167 12.5691 5.775C11.6441 4.90833 10.59 4.46667 9.40664 4.45L11.9316 7.025C12.0816 7.175 12.1566 7.35833 12.1566 7.575C12.1566 7.79167 12.0816 7.975 11.9316 8.125L8.63164 11.225C8.48164 11.375 8.29831 11.45 8.08164 11.45C7.86497 11.45 7.68164 11.375 7.53164 11.225L5.10664 8.825C5.15664 10.1083 5.61221 11.1667 6.47334 12C7.33447 12.8333 8.34557 13.25 9.50664 13.25C9.92331 13.25 10.365 13.1833 10.8316 13.05C11.2983 12.9167 11.7066 12.7167 12.0566 12.45L18.9816 19.375Z"
      fill="black"
    />
  </svg>
);

export default SvgIconServiceHistory;
